import { createAsyncThunk  } from "@reduxjs/toolkit";
import axios from 'axios';

const backendURL = process.env.REACT_APP_API_URL;

export const registerUser = createAsyncThunk(
    'auth/register',
    async ({ firstName, email, password }, { rejectWithValue }) => {
      try {
        const config = {
          method: 'post',
          url: `${backendURL}/register`,
          data: JSON.stringify({ firstName, email, password }),
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        }
        const { data } = await axios(config);
        return data.data;

      } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
      }
    }
);

export const userLogin = createAsyncThunk(
    'auth/login',
    async ({ email, password }, { rejectWithValue }) => {
      try {
        const config = {
          method: 'post',
          url:`${backendURL}/signin`,
          data: { email, password },
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
          },
        }
        const { data } = await axios(config);
        return data.data;

      } catch (error) {
        if (error.response && error.response.data.message) {
            console.log(error.response.data.message);
            return rejectWithValue(error.response.data.message);
        } else {
            console.log(error.message);
            return rejectWithValue(error.message);
        }
      }
    }
);