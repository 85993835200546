import cogoToast from 'cogo-toast';
import { createSlice } from "@reduxjs/toolkit";
import {userLogin, registerUser } from '../Action/AuthAction';

const initialState = {
  loading: false,
  userInfo: {},
  error: null,
  info: null,
  success: false,
  isLogin: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLogout: (state) => {
        state.loading = false;
        state.userInfo = {};
        state.info = null;
        state.error = null;
        state.success = false;
        state.isLogin = false;
    }
  },
  extraReducers: (builder) => {
    
    builder.addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.userInfo = {};
        state.info = null;
        state.error = null;
        state.success = false;
        state.isLogin = false;
    });
    builder.addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.info = null;
        if(payload.error){
            state.error = payload.message;
            state.userInfo = {};
            state.success = false;
            state.isLogin = false;
            cogoToast.error( state.error, {position: "top-right"});
        }
        else{
            state.error = null;
            state.success = true;
            state.isLogin = true;
            state.userInfo = payload;
            cogoToast.success("Correct Login", {position: "top-right"});
        }
    });
    builder.addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.userInfo = {};
        state.info = null;
        state.success = false;
        state.isLogin = false;
        cogoToast.error( state.error, {position: "top-right"});
    });
    
    builder.addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.userInfo = {};
        state.info = null;
        state.success = false;
        state.isLogin = false;
    });
    builder.addCase(registerUser.fulfilled, (state, {payload}) => {
        state.loading = false;
        state.success = true;
        state.info = "User Created!";
        state.error = null;
        state.userInfo = {};
        state.isLogin = false;
        cogoToast.success(state.info, {position: "top-right"});
    });
    builder.addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.userInfo = {};
        state.info = null;
        state.success = false;
        state.isLogin = false;
        cogoToast.error( state.error, {position: "top-right"});
    });
  }
})

export const getAuthState = (state) => state.auth;
export const getUserInfo = (state) => state.auth.userInfo;
export const isLogining = (state) => state.auth.isLogin;

export const { setLogout } = authSlice.actions;
export default authSlice.reducer;