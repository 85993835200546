import { createAsyncThunk  } from "@reduxjs/toolkit";
import axios from 'axios';

const backendURL = process.env.REACT_APP_API_URL;

export const fetchProduct = createAsyncThunk(
  'product/fetchProduct',
  async () => {
    const conf = {
      method:'get',
      url: `${backendURL}/products`
    }
    const {data} = await axios(conf);
    return data.data;
  },
);

export const fetchProductLimit = createAsyncThunk(
  'product/fetchProductLimit',
  async ({skip, limit, category}) => {
    const conf = {
      method:'get',
      url: `${backendURL}/productslimit`,
      params: {
        skip: skip,  
        limit: limit,
        category: category
      }
    }
    const {data} = await axios(conf);
    return data.data;
  },
);

export const fetchProductId = createAsyncThunk(
  'product/fetchProductId',
  async (id) => {
    const conf = {
      method:'get',
      url: `${backendURL}/products/${id}`
    }
    const {data} = await axios(conf);
    return data.data;
  },
);

export const fetchCategoryId = createAsyncThunk(
  'product/fetchCategoryId',
  async (id) => {
    const conf = {
      method:'get',
      url: `${backendURL}/category/${id}`
    }
    const {data} = await axios(conf);
    return data.data;
  },
);

export const fetchSearchProduct = createAsyncThunk(
  'product/fetchSearchProduct',
  async (_search) => {
    const conf = {
      method:'post',
      url: `${backendURL}/products`,
      data: {search: _search}
    }
    const {data} = await axios(conf);
    return data.data;
  },
);