import cogoToast from 'cogo-toast';
import { createSlice } from "@reduxjs/toolkit";
import { orderUser, addContacts, getContacts } from '../Action/userActions';

const initialState = {
  loading: false,
  userInfo: {},
  error: null,
  info: null,
  success: false
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    
    setCredentials: (state, { payload }) => {
        //state.userInfo = payload;
    },
    clearState: (state) => {
        state.loading = false;
        state.userInfo = {};
        state.info = null;
        state.error = null;
        state.success = false;
    }
  },
  extraReducers: (builder) => {

  builder.addCase(orderUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.info = null;
        state.userInfo = {};
        state.success = false;
    });
  builder.addCase(orderUser.fulfilled, (state, {payload}) => {
        state.loading = false;
        state.success = true;
        state.info = "Data user is loading or update";
        state.error = null;
        state.userInfo = {...state.userInfo, orders: payload}
        cogoToast.success(state.info, {position: "top-right"});
    });
  builder.addCase(orderUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.userInfo = {};
        state.info = null;
        state.success = false;
        cogoToast.error( state.error, {position: "top-right"});
    });

  builder.addCase(getContacts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.info = null;
        state.userInfo = {};
        state.success = false;
    });
  builder.addCase(getContacts.fulfilled, (state, {payload}) => {

        state.loading = false;
        state.success = true;
        state.info = "Data user is loading";
        state.error = null;
        state.userInfo = {...state.userInfo, contact: payload[0]}
        cogoToast.success(state.info, {position: "top-right"});
    });
  builder.addCase(getContacts.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.userInfo = {};
        state.info = null;
        state.success = false;
        cogoToast.error( state.error, {position: "top-right"});
    });

  builder.addCase(addContacts.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.info = null;
      state.success = false;
  });
  builder.addCase(addContacts.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.success = true;
      state.info = "Add Contacts!";
      state.error = null;
      //state.userInfo.contact = payload;
      cogoToast.success(state.info, {position: "top-right"});
  });
  builder.addCase(addContacts.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.info = null;
      state.success = false;
      cogoToast.error( state.error, {position: "top-right"});
  });
  }
})

export const getUserInfo = (state) => state.user;
export const getUserContact = (state) => state.user.userInfo.contact;

export const { setCredentials, clearState } = userSlice.actions;
export default userSlice.reducer;