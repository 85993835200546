import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from './routes';
import { useSelector } from "react-redux";
import { selectAllProduct, setStatusRequest } from "./store/slices/product-slice";

const NotFound = lazy(() => import("./pages/other/NotFound"));
const NotServer = lazy(() => import("./pages/other/NotServer"));

function App() {

  const productData = useSelector(selectAllProduct);
  const productStatus  = useSelector(setStatusRequest);

  return (
      <Router>
        <ScrollToTop>
          <Suspense
            fallback={
              <div className="flone-preloader-wrapper">
                <div className="flone-preloader">
                  <span></span>
                  <span></span>
                </div>
              </div>
            }
          >
            <Routes>
                {!productData.error && productStatus.status !== "failed" ? routes.map((route, idx) => {
                  return(
                    <Route 
                        key={idx}
                        path={process.env.PUBLIC_URL + route.path}
                        exact={route.exact}
                        name={route.name}
                        element={<route.element />}
                    />
                  )
                  })
                  :
                  <>
                    <Route path="/" element={<NotServer/>} />
                    <Route path="*" element={<NotServer/>} />
                  </>
                }
                <Route path="*" element={<NotFound/>} />
            </Routes>
          </Suspense>
        </ScrollToTop>
      </Router>
  );
};

export default App;