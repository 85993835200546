import {lazy} from 'react';

// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));
const HomeFashionTwo = lazy(() => import("./pages/home/HomeFashionTwo"));
const HomeFashionThree = lazy(() => import("./pages/home/HomeFashionThree"));
const HomeFashionFour = lazy(() => import("./pages/home/HomeFashionFour"));
const HomeFashionFive = lazy(() => import("./pages/home/HomeFashionFive"));
const HomeFashionSix = lazy(() => import("./pages/home/HomeFashionSix"));
const HomeFashionSeven = lazy(() => import("./pages/home/HomeFashionSeven"));
const HomeFashionEight = lazy(() => import("./pages/home/HomeFashionEight"));
const HomeKidsFashion = lazy(() => import("./pages/home/HomeKidsFashion"));
const HomeCosmetics = lazy(() => import("./pages/home/HomeCosmetics"));
const HomeFurniture = lazy(() => import("./pages/home/HomeFurniture"));
const HomeFurnitureTwo = lazy(() => import("./pages/home/HomeFurnitureTwo"));
const HomeFurnitureThree = lazy(() =>import("./pages/home/HomeFurnitureThree"));
const HomeFurnitureFour = lazy(() => import("./pages/home/HomeFurnitureFour"));
const HomeFurnitureFive = lazy(() => import("./pages/home/HomeFurnitureFive"));
const HomeFurnitureSix = lazy(() => import("./pages/home/HomeFurnitureSix"));
const HomeFurnitureSeven = lazy(() => import("./pages/home/HomeFurnitureSeven"));
const HomeElectronics = lazy(() => import("./pages/home/HomeElectronics"));
const HomeElectronicsTwo = lazy(() =>import("./pages/home/HomeElectronicsTwo"));
const HomeElectronicsThree = lazy(() => import("./pages/home/HomeElectronicsThree"));
const HomeBookStore = lazy(() => import("./pages/home/HomeBookStore"));
const HomeBookStoreTwo = lazy(() => import("./pages/home/HomeBookStoreTwo"));
const HomePlants = lazy(() => import("./pages/home/HomePlants"));
const HomeFlowerShop = lazy(() => import("./pages/home/HomeFlowerShop"));
const HomeFlowerShopTwo = lazy(() => import("./pages/home/HomeFlowerShopTwo"));
const HomeOrganicFood = lazy(() => import("./pages/home/HomeOrganicFood"));
const HomeOrganicFoodTwo = lazy(() => import("./pages/home/HomeOrganicFoodTwo"));
const HomeOnepageScroll = lazy(() => import("./pages/home/HomeOnepageScroll"));
const HomeGridBanner = lazy(() => import("./pages/home/HomeGridBanner"));
const HomeAutoParts = lazy(() => import("./pages/home/HomeAutoParts"));
const HomeCakeShop = lazy(() => import("./pages/home/HomeCakeShop"));
const HomeHandmade = lazy(() => import("./pages/home/HomeHandmade"));
const HomePetFood = lazy(() => import("./pages/home/HomePetFood"));
const HomeMedicalEquipment = lazy(() => import("./pages/home/HomeMedicalEquipment"));
const HomeChristmas = lazy(() => import("./pages/home/HomeChristmas"));
const HomeBlackFriday = lazy(() => import("./pages/home/HomeBlackFriday"));
const HomeBlackFridayTwo = lazy(() => import("./pages/home/HomeBlackFridayTwo"));
const HomeValentinesDay = lazy(() => import("./pages/home/HomeValentinesDay"));

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ShopGridFilter = lazy(() => import("./pages/shop/ShopGridFilter"));
const ShopGridTwoColumn = lazy(() => import("./pages/shop/ShopGridTwoColumn"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopGridRightSidebar = lazy(() => import("./pages/shop/ShopGridRightSidebar"));
const ShopListStandard = lazy(() => import("./pages/shop/ShopListStandard"));
const ShopListFullWidth = lazy(() => import("./pages/shop/ShopListFullWidth"));
const ShopListTwoColumn = lazy(() => import("./pages/shop/ShopListTwoColumn"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() => import("./pages/shop-product/ProductTabLeft"));
const ProductTabRight = lazy(() => import("./pages/shop-product/ProductTabRight"));
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() => import("./pages/shop-product/ProductFixedImage"));

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = lazy(() => import("./pages/blog/BlogDetailsStandard"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/myAccount/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const StatusOrder = lazy(() => import("./pages/other/StatusOrder"));
const Cart = lazy(() => import("./pages/other/Cart/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/checkout/Checkout"));

const routers = [
    {path: "/", element:HomeFashion},
    /* Homepages */
    {path: "/home-fashion", element: HomeFashion},
    {path: "/home-fashion-two", element: HomeFashionTwo},
    {path: "/home-fashion-three", element: HomeFashionThree},
    {path: "/home-fashion-four", element: HomeFashionFour},
    {path: "/home-fashion-five", element: HomeFashionFive},
    {path: "/home-fashion-six", element: HomeFashionSix},
    {path: "/home-fashion-seven", element: HomeFashionSeven},
    {path: "/home-fashion-eight", element: HomeFashionEight},
    {path: "/home-kids-fashion", element: HomeKidsFashion},
    {path: "/home-cosmetics", element: HomeCosmetics},
    {path: "/home-furniture", element: HomeFurniture},
    {path: "/home-furniture-two", element: HomeFurnitureTwo},
    {path: "/home-furniture-three", element: HomeFurnitureThree},
    {path: "/home-furniture-four", element: HomeFurnitureFour},
    {path: "/home-furniture-five", element: HomeFurnitureFive},
    {path: "/home-furniture-six", element: HomeFurnitureSix},
    {path: "/home-furniture-seven", element: HomeFurnitureSeven},
    {path: "/home-electronics", element: HomeElectronics},
    {path: "/home-electronics-two", element: HomeElectronicsTwo},
    {path: "/home-electronics-three", element: HomeElectronicsThree},
    {path: "/home-book-store", element: HomeBookStore},
    {path: "/home-book-store-two", element: HomeBookStoreTwo},
    {path: "/home-plants", element: HomePlants},
    {path: "/home-flower-shop", element: HomeFlowerShop},
    {path: "/home-flower-shop-two", element: HomeFlowerShopTwo},
    {path: "/home-organic-food", element: HomeOrganicFood},
    {path: "/home-organic-food-two", element: HomeOrganicFoodTwo},
    {path: "/home-onepage-scroll", element: HomeOnepageScroll},
    {path: "/home-grid-banner", element: HomeGridBanner},
    {path: "/home-auto-parts", element: HomeAutoParts},
    {path: "/home-cake-shop", element: HomeCakeShop},
    {path: "/home-handmade", element: HomeHandmade},
    {path: "/home-pet-food", element: HomePetFood},
    {path: "/home-medical-equipment", element: HomeMedicalEquipment},
    {path: "/home-christmas", element: HomeChristmas},
    {path: "/home-black-friday", element: HomeBlackFriday},
    {path: "/home-black-friday-two", element: HomeBlackFridayTwo},
    {path: "/home-valentines-day", element: HomeValentinesDay},
    /* Shop pages */
    {path: "/shop-grid-standard", element: ShopGridStandard},
    {path: "/shop-grid-standard/:category", element: ShopGridStandard},
    {path: "/shop-grid-filter", element: ShopGridFilter},
    {path: "/shop-grid-two-column", element: ShopGridTwoColumn},
    {path: "/shop-grid-no-sidebar", element: ShopGridNoSidebar},
    {path: "/shop-grid-full-width", element: ShopGridFullWidth},
    {path: "/shop-grid-right-sidebar", element: ShopGridRightSidebar},
    {path: "/shop-list-standard", element: ShopListStandard},
    {path: "/shop-list-full-width", element: ShopListFullWidth},
    {path: "/shop-list-two-column", element: ShopListTwoColumn},
    /* Shop product pages */
    {path: "/product/:id", element: Product },
    {path: "/product-tab-left/:id", element: ProductTabLeft},
    {path: "/product-tab-right/:id", element: ProductTabRight},
    {path: "/product-sticky/:id", element: ProductSticky},
    {path: "/product-slider/:id", element: ProductSlider},
    {path: "/product-fixed-image/:id", element: ProductFixedImage},
    /* Blog pages */
    {path: "/blog-standard", element: BlogStandard},
    {path: "/blog-no-sidebar", element: BlogNoSidebar},
    {path: "/blog-right-sidebar", element: BlogRightSidebar},
    {path: "/blog-details-standard", element: BlogDetailsStandard},
    /* Other pages */
    {path: "/about", element: About},
    {path: "/contact", element: Contact},
    {path: "/my-account", element: MyAccount, protect: true},
    {path: "/login-register", element: LoginRegister},
    {path: "/status-order/:id", element: StatusOrder},
    {path: "/status-order", element: StatusOrder},
    {path: "/cart", element: Cart},
    {path: "/wishlist", element: Wishlist},
    {path: "/compare", element: Compare},
    {path: "/checkout", element: Checkout}   
]
export default routers;
