//import cogoToast from 'cogo-toast';
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nonameInfo: {}
}

const nonameSlice = createSlice({
  name: 'noname',
  initialState,
  reducers: {
    setNonameInfo: (state, action) => {
        state.nonameInfo = action.payload;
    },
    clearNonameState: (state) => {
        state.nonameInfo = {};
    }
  }
})

export const getNonameInfo = (state) => state.noname;

export const { setNonameInfo, clearNonameState } = nonameSlice.actions;
export default nonameSlice.reducer;