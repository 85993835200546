import { createAsyncThunk  } from "@reduxjs/toolkit";
import axios from 'axios';

const backendURL = process.env.REACT_APP_API_URL;

export const orderUser = createAsyncThunk(
  'user/ditails',
  async ({ id, token }, { rejectWithValue }) => {
    try {
      axios.defaults.headers.common = {'x-access-token': `${token}`}
      //axios.defaults.headers.common = {'id-user': `${id}`}
      //axios.defaults.headers.common["x-access-token"] = token;
      const config = {
        method: 'get',
        url:`${backendURL}/orders`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'id-user': `${id}`
        },
        
      }
      const { data } = await axios(config);
      return data.data;

    } catch (error) {
      if (error.response && error.response.data.message) {
          console.log(error.response.data.message);
          return rejectWithValue(error.response.data.message);
      } else {
          console.log(error.message);
          return rejectWithValue(error.message);
      }
    }
  }
);

export const addContacts = createAsyncThunk(
  'user/addcontacts',
  async ({ contacts, token }, { rejectWithValue }) => {
    try {
      axios.defaults.headers.common = {'x-access-token': `${token}`}
      const config = {
        method: 'post',
        url:`${backendURL}/addcontact`,
        data: contacts,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
      }
      const { data } = await axios(config);
      return data.data;

    } catch (error) {
      if (error.response && error.response.data.message) {
          console.log(error.response.data.message);
          return rejectWithValue(error.response.data.message);
      } else {
          console.log(error.message);
          return rejectWithValue(error.message);
      }
    }
  }
);

export const getContacts = createAsyncThunk(
  'user/getcontacts',
  async ({ token }, { rejectWithValue }) => {
    try {
      axios.defaults.headers.common = {'x-access-token': `${token}`}
      const config = {
        method: 'get',
        url:`${backendURL}/getcontact`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
      }
      const { data } = await axios(config);
      return data.data.contact;

    } catch (error) {
      if (error.response && error.response.data.message) {
          console.log(error.response.data.message);
          return rejectWithValue(error.response.data.message);
      } else {
          console.log(error.message);
          return rejectWithValue(error.message);
      }
    }
  }
);